"use client";

import Link from "next/link";
import { Button } from "components/ui/button";


interface topicInterface {
  attributes: {
      title: string;
      key: string;
  }
}

export default function TopicTag({ topic }: { topic: topicInterface }) {
  return (
    <Button
      asChild
      key={topic?.attributes.title}
      variant="secondary"
      aria-label='link'
      className="h-7 rounded-full bg-[#E2E2E2] px-[12px] pt-1 pb-0 text-[10px] font-bold"
    >
      <Link
        href={`/blog/topic/${topic?.attributes.key.toLowerCase()}`}
        className="text-[10px] font-bold uppercase"
        onClick={() => {
          localStorage.setItem("currentPage", "1");
        }}
      >
        {topic?.attributes.title}
      </Link>
    </Button>
  );
}
