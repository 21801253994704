"use client";
import React from "react";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";

const AspectRatioComponent = ({ children, aspectRatio }) => {
  return (
    <AspectRatio.Root ratio={aspectRatio.width / aspectRatio.height}>
      {children}
    </AspectRatio.Root>
  );
};

export default AspectRatioComponent;
